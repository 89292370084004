import {
    Link as ChakraLink,
    Text,
    Input,
    Box,
    Badge,
    Image,
    SimpleGrid,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    useDisclosure,
    Select,
    Heading,
    Avatar,
    Grid,
    GridItem,
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    Skeleton,
    Center,
    Divider,
    Spinner,
    Progress,
    Flex,
    Stat,
    StatLabel,
    StatNumber,
    StatHelpText,
    StatArrow,
    StatGroup,
  } from '@chakra-ui/react'

import {IoSettings, IoExit,IoArrowUpCircle,IoCard, IoCheckmarkCircleOutline, IoBriefcase, IoPerson, IoMail,IoInformationCircle, IoCheckmarkDoneCircleSharp, IoCash,IoCheckmarkCircle, IoDownload ,IoCloudUpload,IoSave,IoDocumentAttachOutline , IoPersonAdd, IoAddCircle, IoShare,IoTrash, IoPersonCircleSharp, IoTimer, IoLockClosed, IoCloseCircle} from 'react-icons/io5';


import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import { resolve } from 'path';
import Head from 'next/head';

const SubscriptionManager = ({ session, forceDisplay,  isUpgradeOpen, onUpgradeOpen, onUpgradeClose}) => {

    


    const paymentPrices = {
        monthly: {
            business: {
                price: "9.99",
                stripeId: "price_1N1BgEDbs3ThQfLDWZMh9aQE"
            },
            professional: {
                price: "14.99",
                stripeId: "price_1N1Bg7Dbs3ThQfLDRxyrNZgt"
            }},
        annual: {
            business: {
                price: "8.25",
                annualPrice: "99.00",
                stripeId: "price_1N1BgEDbs3ThQfLDtHXIMyNB"
            },
            professional: {
                price: "12.41",
                annualPrice: "149.00",
                stripeId: "price_1N1Bg7Dbs3ThQfLD3rd3ksvx"
            }}
        };

    React.useEffect(() => {

    }, )

    const [subscriptionDuration, setSubscriptionDuration] = useState("annual");

    const api_url = process.env.NEXT_PUBLIC_API_URL

    const changeSubscriptionDuration = async (duration) =>{
        setSubscriptionDuration(duration)
    }
    const makeSubscription = async (subscriptionSelected) =>{
    
        
        const fetchData = await fetch(`${api_url}/teams/subscribe/${subscriptionSelected}`, {
          method: "POST",
          headers: {
            'Authorization': `Bearer ${session.accessToken}`,
            'Content-Type': 'application/json'
          }
        })
        const fetchDataJson = await fetchData.json();
        if("statusCode" in fetchDataJson){
        }else{ 
            window.location.href = fetchDataJson.url;
        }
    }

    let subscriptionElement = (
    <Box width={"100%"} >
    <Heading mb="3" size={"lg"}>
        Subscription
    </Heading>

    <Box onClick={onUpgradeOpen}  padding="3" mb="5" backgroundColor="white" borderWidth='1px' borderRadius='lg' style={{transition: "all 0.2s"}}
        _hover={{
        cursor: "pointer",
        backgroundColor: "#f4f4f4"
        }}
    >
        <StatGroup mb="2">
            <Stat>
                <StatLabel>Subscription</StatLabel>
                <StatNumber>{session["team_data"]["subscription"]}</StatNumber>
            </Stat>
            <Stat>
                <StatLabel>Invoices Managed</StatLabel>
                <StatNumber>{session["usage"]["used"]}</StatNumber>
            </Stat>
            <Stat>
                <StatLabel>Invoice Limit</StatLabel>
                {session["usage"]["limit"] > 50 &&
                <StatNumber>                            
                    ∞
                </StatNumber>
                }
                {session["usage"]["limit"] <= 50 &&
                <StatNumber>                            
                    50
                </StatNumber>
                }
            </Stat>
            {session["team_data"]["subscription"] == "BASIC" &&
            <Stat>
                <StatLabel>Percentage Used</StatLabel>
                <StatNumber>{((session["usage"]["used"]/ session["usage"]["limit"])*100).toFixed(0)}%</StatNumber>
            </Stat>
            }
            {session["team_data"]["subscription"] != "BASIC" &&
            <Stat>
                <StatLabel>Users in your Team</StatLabel>
                <StatNumber>{session["usage"]["users"]}</StatNumber>
            </Stat>
            }
        </StatGroup>
        <Progress colorScheme={"orange"} borderRadius="5px" height='20px' value={(session["usage"]["used"]/ session["usage"]["limit"])*100} />
        </Box>
        <Modal size={"4xl"} onClose={onUpgradeClose} isOpen={isUpgradeOpen}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Upgrade your plan</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                {session["team_data"]["subscription"] != "PROFESSIONAL" &&
                <Heading as="h3" size="sm" mt="0" mb="2">
                    Payment Period (select):
                </Heading>
                }
                {(subscriptionDuration == "annual" && session["team_data"]["subscription"] != "PROFESSIONAL") &&
                <Flex gap="5" mb="3">
                    <Button onClick={()=>{changeSubscriptionDuration("annual")}} width={"full"} colorScheme="gray">Annual Payment (20% Discount)</Button>
                    <Button onClick={()=>{changeSubscriptionDuration("monthly")}} width={"full"} 
                        border='2px'
                        borderColor='gray.200'
                        colorScheme='black' 
                        variant="outline"
                        color="gray.700"
                    > 
                        Monthly Payment 
                    </Button>
                </Flex>
                } 
                {(subscriptionDuration == "monthly" && session["team_data"]["subscription"] != "PROFESSIONAL") &&
                <Flex gap="5" mb="3"> 
                    <Button onClick={()=>{changeSubscriptionDuration("annual")}} width={"full"} 
                        border='2px'
                        borderColor='gray.200'
                        colorScheme='black' 
                        variant="outline"
                        color="gray.700"
                    >
                        Annual Payment (20% Discount)
                    </Button>
                    <Button onClick={()=>{changeSubscriptionDuration("monthly")}} width={"full"}  colorScheme="gray" >Monthly Payment</Button>
                </Flex>
                }
                {session["team_data"]["subscription"] != "PROFESSIONAL" &&
                <Heading as="h3" size="sm" mt="0" mb="2">
                    Suggested Plan:
                </Heading>
                }
                {session["team_data"]["subscription"] != "PROFESSIONAL" &&
                <Box  padding="3" mb="5" backgroundColor="#fff" borderWidth='1px' borderColor={"orange.300"} borderRadius='lg' style={{transition: "all 0.2s"}}
                    _hover={{
                    cursor: "pointer",
                    backgroundColor: "#f4f4f4"
                    }}
                >
                    <SimpleGrid mb={"2"} columns={4} spacing={0}>
                        <GridItem colSpan={2}>
                            <Stat>
                                <StatNumber fontWeight={"bold"}>Professional</StatNumber>
                                <StatHelpText>made for medium sized businesses</StatHelpText>
                            </Stat>
                            <Flex>
                                <Center mr="2">
                                    <IoCheckmarkCircle/>
                                </Center>
                                <Box flex='1' >
                                    <Text fontWeight={"bold"}>Up to 100 Users</Text>
                                </Box>
                            </Flex>
                            <Flex mt="1">
                                <Center mr="2">
                                    <IoCheckmarkCircle/>
                                </Center>
                                <Box flex='1' >
                                    <Text fontWeight={"bold"}>50 GB Storage per User</Text>
                                </Box>
                            </Flex>
                            <Flex mt="1" mb="3">
                                <Center mr="2">
                                    <IoCheckmarkCircle/>
                                </Center>
                                <Box flex='1' >
                                    <Text fontWeight={"bold"}>25 Custom Budgets</Text>
                                </Box>
                            </Flex>
                            <Divider></Divider>
                            <Stat mt="3">
                                <StatNumber fontSize={"1em"} fontWeight={"bold"}>Total: {((paymentPrices[subscriptionDuration].professional.price)*session["usage"]["users"]).toFixed(2)}€</StatNumber>
                                <StatHelpText mb="0">per month for {session["usage"]["users"]} User </StatHelpText>
                            </Stat>
                        </GridItem>
                        <GridItem colSpan={2}>
                            <Stat textAlign={"right"}>
                                <StatNumber fontWeight={"bold"}>{paymentPrices[subscriptionDuration].professional.price}€</StatNumber>
                                <StatHelpText>per user & month </StatHelpText>
                            </Stat>
                            <Flex>
                                <Center mr="2">
                                    <IoCheckmarkCircle/>
                                </Center>
                                <Box flex='1' >
                                    <Text fontWeight={"bold"}>100.000 Invoices</Text>
                                </Box>
                            </Flex>
                            <Flex mt="1">
                                <Center mr="2">
                                    <IoCheckmarkCircle/>
                                </Center>
                                <Box flex='1' >
                                    <Text fontWeight={"bold"}>10 Approval Process</Text>
                                </Box>
                            </Flex>
                            <Flex mt="1" mb="3">
                                <Center mr="2">
                                    <IoCheckmarkCircle/>
                                </Center>
                                <Box flex='1' >
                                    <Text fontWeight={"bold"}>10 Custom Groups</Text>
                                </Box>
                            </Flex>
                            <Divider></Divider>
                            <Button onClick={()=>{makeSubscription(paymentPrices[subscriptionDuration].professional.stripeId)}} size={"md"} mt="4" width={"full"} colorScheme={"rekening.main"} color="black">
                                <IoArrowUpCircle color="black" />
                                <Text color="black" ml="1">Upgrade Now to Professional</Text>
                            </Button>
                        </GridItem>
                    </SimpleGrid>
                </Box>
                }

                {session["team_data"]["subscription"] == "BASIC" || session["team_data"]["subscription"] == "STARTER" && 
                <Box  padding="3" mb="5" backgroundColor="white" borderWidth='1px'  borderColor={"gray.200"} borderRadius='lg' style={{transition: "all 0.2s"}}
                    _hover={{
                    cursor: "pointer",
                    backgroundColor: "#f4f4f4"
                    }}
                >
                    <SimpleGrid mb={"2"} columns={4} spacing={5}>
                        <GridItem colSpan={2}>
                            <Stat>
                                <StatNumber fontWeight={"bold"}>Business</StatNumber>
                                <StatHelpText>made for medium sized businesses</StatHelpText>
                            </Stat>
                            <Flex>
                                <Center mr="2">
                                    <IoCheckmarkCircle/>
                                </Center>
                                <Box flex='1' >
                                    <Text fontWeight={"bold"}>Up to 10 Users</Text>
                                </Box>
                            </Flex>
                            <Flex mt="1">
                                <Center mr="2">
                                    <IoCheckmarkCircle/>
                                </Center>
                                <Box flex='1' >
                                    <Text fontWeight={"bold"}>Advanced 20 GB Storage per User</Text>
                                </Box>
                            </Flex>
                            <Flex mt="1" mb="3">
                                <Center mr="2">
                                    <IoCheckmarkCircle/>
                                </Center>
                                <Box flex='1' >
                                    <Text fontWeight={"bold"}>5 Custom Budgets</Text>
                                </Box>
                            </Flex>
                            <Divider></Divider>
                            <Stat mt="3">
                                <StatNumber fontSize={"1em"} fontWeight={"bold"}>Total: {((paymentPrices[subscriptionDuration].business.price)*session["usage"]["users"]).toFixed(2)}€</StatNumber>
                                <StatHelpText mb="0">per month for {session["usage"]["users"]} User </StatHelpText>
                            </Stat>
                        </GridItem>
                        <GridItem colSpan={2}>
                            <Stat textAlign={"right"}>
                                <StatNumber fontWeight={"bold"}>{(paymentPrices[subscriptionDuration].business.price)}€</StatNumber>
                                <StatHelpText>per user & month </StatHelpText>
                            </Stat>
                            <Flex>
                                <Center mr="2">
                                    <IoCheckmarkCircle/>
                                </Center>
                                <Box flex='1' >
                                    <Text fontWeight={"bold"}>25.000 Invoices</Text>
                                </Box>
                            </Flex>
                            <Flex mt="1">
                                <Center mr="2">
                                    <IoCheckmarkCircle/>
                                </Center>
                                <Box flex='1' >
                                    <Text fontWeight={"bold"}>5 Approval Workflows</Text>
                                </Box>
                            </Flex>
                            <Flex mt="1" mb="3">
                                <Center mr="2">
                                    <IoCheckmarkCircle/>
                                </Center>
                                <Box flex='1' >
                                    <Text fontWeight={"bold"}>3 Custom Groups</Text>
                                </Box>
                            </Flex>
                            <Divider></Divider>
                            <Button  onClick={()=>{makeSubscription(paymentPrices[subscriptionDuration].business.stripeId)}} size={"md"} mt="4" width={"full"} color="black">
                                <IoArrowUpCircle color="black" />
                                <Text color="black" ml="1">Upgrade Now to Business</Text>
                            </Button>
                        </GridItem>
                    </SimpleGrid>
                </Box>
                }
                <Divider></Divider>
                <Heading as="h3" size="sm" mt="5" mb="2">
                    Current Plan:
                </Heading>

                <Box  padding="3" mb="5" backgroundColor="white" borderWidth='1px'  borderColor={"gray.200"} borderRadius='lg' style={{transition: "all 0.2s"}}>
                    <StatGroup mb="2">
                        <Stat>
                            <StatNumber>{session["team_data"]["subscription"]}</StatNumber>
                            <StatHelpText>Subscription</StatHelpText>
                        </Stat>
                        <Stat>
                            <StatNumber>{session["usage"]["used"]}</StatNumber>
                            <StatHelpText>Invoices Managed</StatHelpText>
                        </Stat>
                        <Stat>
                            {session["usage"]["limit"] > 50 &&
                            <StatNumber>                            
                                    ∞
                            </StatNumber>
                            }
                            {session["usage"]["limit"] <= 50 &&
                            <StatNumber>                            
                                    50
                            </StatNumber>
                            }
                            <StatHelpText>Invoice Limit</StatHelpText>
                        </Stat>
                        {session["cost"]["subscriptionDuration"] == "year" &&
                        <Stat>
                            <StatNumber>{(session["cost"]["pricePerUser"]*session["usage"]["users"])/100}€</StatNumber>
                            <StatHelpText>Cost per year</StatHelpText>
                        </Stat>
                        }
                    </StatGroup>
                    <Button  onClick={()=>{window.open("http://billing.stripe.com/p/login/aEU3eo8HobBFe7ScMM","_blank")}} size={"md"} mt="4" width={"full"} color="black">
                        <IoCard color="black" />
                        <Text color="black" ml="1">Manage Payments, Invoices & Subscription</Text>
                    </Button>
                </Box>
                </ModalBody>
            </ModalContent>
        </Modal>
    </Box>
    );

    if((session["team_data"]["subscription"] == "BUSINESS" || session["team_data"]["subscription"] == "PROFESSIONAL") && forceDisplay != true){
        subscriptionElement = (<div></div>)
    }

    return subscriptionElement;
};

export default SubscriptionManager;