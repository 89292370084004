import {
    Link as ChakraLink,
    Text,
    Input,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    useDisclosure,
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
    others,
    useToast,
    InputGroup,
    InputLeftElement,
    FormLabel
  } from '@chakra-ui/react'

import {IoSettings, IoExit, IoChevronForward, IoBriefcase, IoPerson, IoMail,IoInformationCircle, IoCheckmarkDoneCircleSharp, IoCash,IoCheckmarkCircle, IoDownload ,IoCloudUpload,IoSave,IoDocumentAttachOutline , IoPersonAdd, IoAddCircle, IoShare,IoTrash, IoPersonCircleSharp, IoTimer, IoLockClosed, IoCloseCircle} from 'react-icons/io5';

import {
  AsyncCreatableSelect,
  AsyncSelect,
  CreatableSelect,
  Select,
  useChakraSelectProps
} from "chakra-react-select";

import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import { resolve } from 'path';
import axios from 'axios';

const InviteUserModal = ({ isOpen , onClose, session }) => {
  const [isBrowser, setIsBrowser] = useState(false);
  const [newUser, setNewUser] = useState({username: "", email: "", role: "READER", groups: [], invitationId: "EMPTY"});
  const [errorUserInvite, setErrorUserInvite] = useState("Please fill out all fields");
  const [isLoadingUserInvite, setIsLoadingUserInvite] = useState(false);

  const [groupsListOptions, setGroupsListOptions] = useState([])

  const toast = useToast()

  const { isOpen: isRolesOpen, onOpen: onRolesOpen, onClose: onRolesClose } = useDisclosure()

  const api_url = process.env.NEXT_PUBLIC_API_URL

  useEffect(() => {
    setIsBrowser(true);
  }, []);

  const loadGroups = async () =>{
    let groupsData;
    try{
      const fetchData = axios.get(`${api_url}/groups`, { 
        headers: { Authorization: `Bearer ${session.accessToken}` }
      });
      const data = await fetchData;

      if(data.status != 403 && data.status != 500){
        groupsData = data.data;
      }
    }catch (ex){
      return
    }
    
    let groupsListTemp = [];
    groupsData.map((singleGroup)=>{
      groupsListTemp.push(
        {
          label: singleGroup.title,
          value: singleGroup._id,
        })
    })
    setGroupsListOptions([...groupsListTemp]);
  
  }


  React.useEffect(() => {
    loadGroups()
  }, [isOpen])

  const handleCloseClick = (e) => {
    e.preventDefault();
    onClose();
  };
  const userNotification = async (title, subTitle, status) => {
    toast({
      title: title,
      description: subTitle,
      status: status,
      duration: 5000,
      isClosable: true,
      position: 'bottom',
    })
  }

  const createNewUser = async () =>{
    let body = newUser;
    setIsLoadingUserInvite(true)
    const fetchData = await fetch(`${api_url}/users/`, {
      method: "POST",
      headers: {
        'Authorization': `Bearer ${session.accessToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    })
    
    if(fetchData.status == 201){
        const response = await fetchData.json();
        setIsLoadingUserInvite(false)
        onClose()
        setNewUser({username: "", email: "", role: "READER", invitationId: "EMPTY", groups:[]});
        userNotification("Successfully Invited", "Your new user "+body.username+" has been successfully invited", "success");
    }else{
        const response = await fetchData.json();
        setIsLoadingUserInvite(false)
        if(response.message.length == 1){
            userNotification("Error", response["message"][0], "error");
        }else{
            userNotification("Error", response["message"], "error");
        }
    }
  }


    
  const onChangeText = (key, val) => {
    const newUserTemp = newUser;

    if(key == "groups"){
      let tempArrayGroups = [];
      val.map((singleGroup)=>{
        tempArrayGroups.push(singleGroup.value)
      })
      val = tempArrayGroups;
    }

    newUserTemp[key] = val;
    setNewUser({...newUserTemp});
  }

  const modalContent = isOpen ? (
   <div>
    <Modal isOpen={isOpen} onClose={onClose}>
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>Add New Users To Your Team</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <Text fontSize={"smaller"} mb={"0"} fontWeight={"bold"}>Full Name</Text>
        <InputGroup  mb="3">
            <InputLeftElement
                pointerEvents='none'
                children={<IoPerson color='#718096' fontSize={"1.3em"}/>}
            />
            <Input value={newUser.username} onChange={evt =>  onChangeText(`username`, evt.target.value)} autoFocus placeholder='Full Name (required)' />
        </InputGroup>

        <Text mt="3" fontSize={"smaller"} mb={"0"} fontWeight={"bold"}>Business E-Mail</Text>
        <InputGroup>
            <InputLeftElement
                pointerEvents='none'
                children={<IoMail color='#718096' fontSize={"1.3em"}/>}
            />
            <Input value={newUser.email} onChange={evt =>  onChangeText(`email`, evt.target.value)}  type="email" placeholder='Business E-Mail (required)' />
        </InputGroup>

        <Text mt="3" fontSize={"smaller"} mb={"0"} fontWeight={"bold"}>User Groups</Text>
        <Select
          size={"sm"}
          placeholder="Select groups for this user"
          options={groupsListOptions}
          isMulti
          onChange={(groupList) => onChangeText("groups",groupList)}
        />
        {/*
        <Select value={newUser.role} onChange={evt =>  onChangeText(`role`, evt.target.value)}>
            <option value='ADMIN'>Admin</option>
            <option value='EDITOR'>Accounting</option>
            <option value='READER'>Employee</option>
        </Select>
        <Button mt="5" onClick={onRolesOpen}>
          <IoInformationCircle style={{marginRight: "5px"}}></IoInformationCircle>
          How to choose a role?
        </Button>*/}
        {!(newUser.email != "" && newUser.username != "" && newUser.groups.length != 0) &&
        <Text style={{color: "red", marginTop: 10}}>
          {errorUserInvite}
        </Text>
        }
        {"cost" in session && session.cost.pricePerUser != "" &&
        <Text fontSize={"small"} style={{color: "grey", marginTop: 10}}>
          Your plan is able to add unlimited users. Every additional user cost you  {session.cost.pricePerUser/100}€ per {session.cost.subscriptionDuration} in your current plan. Billing will take place directly after the invitation.
        </Text>
        }
      </ModalBody>

      <ModalFooter>
        <Button onClick={onClose} mr={3}>Cancel</Button>
        <Button isLoading={isLoadingUserInvite} disabled={!(newUser.email != "" && newUser.username != "" && newUser.groups.length != 0)} colorScheme='rekening.main' style={{color: "black"}} onClick={()=>{createNewUser()}}>
          <IoPersonAdd></IoPersonAdd>
          <Text ml="1">Add User</Text>
        </Button>
      </ModalFooter>
    </ModalContent>
  </Modal>
  
  <Modal isOpen={isRolesOpen} size="4xl" onClose={onRolesClose}>
  <ModalOverlay />
  <ModalContent>
    <ModalHeader>Roles & Rights</ModalHeader>
    <ModalCloseButton />
    <ModalBody>
      <TableContainer>
        <Table variant='simple'>
          <Thead>
            <Tr>
              <Th>Feature</Th>
              <Th>Employee</Th>
              <Th>Accounting</Th>
              <Th>Admin</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td>Add new Invoices</Td>
              <Td>
                <IoCheckmarkCircle color='green'/>
              </Td>
              <Td>
                <IoCheckmarkCircle color='green'/>
              </Td>
              <Td>
                <IoCheckmarkCircle color='green'/>
              </Td>
            </Tr>
            <Tr>
              <Td>Upload Invoice Documets</Td>
              <Td>
                <IoCheckmarkCircle color='green'/>
              </Td>
              <Td>
                <IoCheckmarkCircle color='green'/>
              </Td>
              <Td>
                <IoCheckmarkCircle color='green'/>
              </Td>
            </Tr>
            <Tr>
              <Td>Update Invoices</Td>
              <Td>
                <IoCheckmarkCircle color='green'/>
              </Td>
              <Td>
                <IoCheckmarkCircle color='green'/>
              </Td>
              <Td>
                <IoCheckmarkCircle color='green'/>
              </Td>
            </Tr>
            <Tr>
              <Td>View Own Invoices</Td>
              <Td>
                <IoCheckmarkCircle color='green'/>
              </Td>
              <Td>
                <IoCheckmarkCircle color='green'/>
              </Td>
              <Td>
                <IoCheckmarkCircle color='green'/>
              </Td>
            </Tr>
            <Tr>
              <Td>View All Invoices</Td>
              <Td>
                <IoCloseCircle color='red'/>
              </Td>
              <Td>
                <IoCheckmarkCircle color='green'/>
              </Td>
              <Td>
                <IoCheckmarkCircle color='green'/>
              </Td>
            </Tr>
            <Tr>
              <Td>Request Refund</Td>
              <Td>
                <IoCheckmarkCircle color='green'/>
              </Td>
              <Td>
                <IoCheckmarkCircle color='green'/>
              </Td>
              <Td>
                <IoCheckmarkCircle color='green'/>
              </Td>
            </Tr>
            <Tr>
              <Td>Approve Refund</Td>
              <Td>
                <IoCloseCircle color='red'/>
              </Td>
              <Td>
                <IoCheckmarkCircle color='green'/>
              </Td>
              <Td>
                <IoCheckmarkCircle color='green'/>
              </Td>
            </Tr>
            <Tr>
              <Td>Update Refunded Invoices</Td>
              <Td>
                <IoCloseCircle color='red'/>
              </Td>
              <Td>
                <IoCloseCircle color='red'/>
              </Td>
              <Td>
                <IoCheckmarkCircle color='green'/>
              </Td>
            </Tr>
            <Tr>
              <Td>Invite User</Td>
              <Td>
                <IoCloseCircle color='red'/>
              </Td>
              <Td>
                <IoCloseCircle color='red'/>
              </Td>
              <Td>
                <IoCheckmarkCircle color='green'/>
              </Td>
            </Tr>
            <Tr>
              <Td>Update User</Td>
              <Td>
                <IoCloseCircle color='red'/>
              </Td>
              <Td>
                <IoCloseCircle color='red'/>
              </Td>
              <Td>
                <IoCheckmarkCircle color='green'/>
              </Td>
            </Tr>
            <Tr>
              <Td>Delete User</Td>
              <Td>
                <IoCloseCircle color='red'/>
              </Td>
              <Td>
                <IoCloseCircle color='red'/>
              </Td>
              <Td>
                <IoCheckmarkCircle color='green'/>
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>
    </ModalBody>

    <ModalFooter>
      <Button onClick={onRolesClose} mr={3}>Close</Button>
    </ModalFooter>
  </ModalContent>
</Modal>
</div>
  ) : null;
  
  return modalContent
};

export default InviteUserModal;