import {
    Link as ChakraLink,
    Text,
    Input,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    useDisclosure,
    Image, 
    Heading, 
    Badge,
    Avatar,
    Tabs,
    TabList,
    Tab,
    Flex,
    TabIndicator,
    Spacer,
    Box,
    Center
  } from '@chakra-ui/react'

import { useRouter } from 'next/router'
import {IoHelpCircle, IoSettings,IoCart, IoReceipt, IoEarth,IoExit, IoBarChart, IoBriefcase, IoAlarm, IoHandLeft, IoPerson, IoMail,IoInformationCircle, IoCheckmarkDoneCircleSharp, IoCash,IoCheckmarkCircle, IoDownload ,IoCloudUpload,IoSave,IoDocumentAttachOutline , IoPersonAdd, IoAddCircle, IoShare,IoTrash, IoPersonCircleSharp, IoTimer, IoLockClosed, IoCloseCircle, IoGlobe} from 'react-icons/io5';


import React, { useEffect, useState, useRef } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import { resolve } from 'path';
import { updateLayoutMeasurement } from 'framer-motion/types/render/dom/projection/utils';
import InviteUserModal from './InviteUserModal';
import { round } from 'lodash';

const TutorialModal = ({isOpen, onClose}) => {
  
    let classList = "custom-modal-tutorial-overlay ";
    if(isOpen){
        classList += "open"
    }
    function mailRequest() {
        const recipient = "kontakt@rekening.de";
        const subject = encodeURIComponent("Rekening support request");
        const body = encodeURIComponent("");
    
        window.location.href = `mailto:${recipient}?subject=${subject}&body=${body}`;
    }

    const menuElement = (
        <div className={classList}>
            <div onClick={onClose} className="custom-modal-tutorial-bg-overlay"></div>
            <div className="custom-modal-tutorial">
                <Heading size="md">
                    Welcome to rekening!
                </Heading>
                <Heading size="sm">
                    In this tutorial you will learn how to use rekening
                </Heading>
                
                <iframe className="full-iframe" src="https://demo.arcade.software/6e01NFei6EOvCz3jxC0Y?embed" loading="lazy"  title="Rekening Expense Dashboard"></iframe>
                <div className="custom-modal-tutorial-footer">
                <Button onClick={()=>{mailRequest()}} mr="2">
                    <IoHelpCircle color="black"></IoHelpCircle>
                    <Text color="black" ml="1">Create Support Request</Text>
                </Button> 
                <Button onClick={onClose} colorScheme='rekening.main'>
                    <IoCheckmarkCircle color="black"></IoCheckmarkCircle>
                    <Text color="black" ml="1">Done & Close Tutorial</Text>
                </Button> 
                </div>
            </div>
        </div>
  );
  
  return menuElement;
};

export default TutorialModal;